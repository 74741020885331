import Cookies from 'js-cookie'
// https://github.com/kbaum/browser-timezone-rails/blob/master/app/assets/javascripts/browser_timezone_rails/set_time_zone.js.erb
Cookies.set(
  "browser.timezone",
  Intl.DateTimeFormat().resolvedOptions().timeZone,
  {
    expires: 365,
    path: '/'
  }
);
