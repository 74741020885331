import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }

  toggleSearchForm() {
    document.getElementById('search-form').classList.toggle('d-none');
    document.getElementById('q').select();
    document.getElementById('q').focus();
  }

  toggleAllowedTimeWindowForm(event) {
    console.log('toggleAllowedTimeWindowForm');
    if (event.target.checked) {
      document.getElementById('time-window-form').classList.remove('d-none');
    } else {
      document.getElementById('time-window-form').classList.add('d-none');
    }

    fetch(`/adult/children/${event.target.dataset.childId}/allowed_time_window`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      },
      body: JSON.stringify({enable: event.target.checked})
    }).then(response => response.json())
      .then(data => {
        if (data.error) {
          this.showError(data.message);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  sendTimeWindow(event) {
    const childId = event.target.dataset.childId;

    const fromHour   = document.getElementById('allowed_window_from_hour').value
    const fromMinute = document.getElementById('allowed_window_from_minute').value
    const toHour     = document.getElementById('allowed_window_to_hour').value
    const toMinute   = document.getElementById('allowed_window_to_minute').value

    fetch(`/adult/children/${childId}/allowed_time_window`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      },
      body: JSON.stringify({
        fromHour, fromMinute, toHour, toMinute
      })
    }).then(response => response.json())
      .then(data => {
        if (data.error) {
          this.showError(data.message);
        } else {
          this.showError(null);
          this.showSuccess();
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  showError(text) {
    if (!text) {
      document.getElementById('time_window_error').classList.add('d-none');
    } else {
      document.getElementById('time_window_error').classList.remove('d-none');
      document.getElementById('time_window_error').innerText = text;
    }
  }

  showSuccess() {
    document.getElementById('time_window_success').classList.remove('d-none');
    setTimeout(() => {
      document.getElementById('time_window_success').classList.add('d-none');
    }, 1000);
  }
}
