global.$ = require("jquery")
global.jQuery = global.$;
require("jquery")
// require("jquery-ui")

import 'browser_timezone_cookie.js'

import 'bootstrap'
import 'jquery.thumb.preview.js'
import moment from 'moment'
require('timeago')
require('chart.js/dist/Chart.min.js')
require('jquery.json-viewer/json-viewer/jquery.json-viewer.js')
require('clipboard/dist/clipboard.min.js')
import LocalTime from  'local-time'

import "@hotwired/turbo-rails"

import "../controllers"

function reach_goal(name, opts) {
  opts = opts || {};
  if (gtag) {
    gtag('event', name, {
      'event_category': opts.category,
      'event_label': opts.label,
      'value': opts.value
    });
  }

  if (window.yaCounter50382304) {
    yaCounter50382304.reachGoal(name, opts);
  } else {
    jQuery(document).on('yacounter50382304inited', function() {
      yaCounter50382304.reachGoal(name, opts);
    });
  }
  return true;
}

function start_timeago() {
  jQuery.timeago.settings.strings = {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: "",
    suffixFromNow: "from now",
    seconds: "1 min",
    minute: "1 min",
    minutes: "%d min",
    hour: "1 h",
    hours: "%d h",
    day: "1 d",
    days: "%d d",
    month: "1 mo",
    months: "%d mo",
    year: "1 y",
    years: "%d y",
    wordSeparator: " ",
    numbers: []
  };
  $("time[data-time-ago]").timeago();
}

$(document).on('ready turbo:load', startJS);

function onPageLoaded(handler) {
  $(document).on('ready turbo:load', handler);
}
window.onPageLoaded = onPageLoaded;

function startJS() {

  start_timeago();
  $("[data-toggle=tooltip]").tooltip({html: true, container:'body', trigger: 'hover', placement:"bottom"});
  $(".scrub_previews img").thumbPreview();
  $('input.focus:visible:first').focus().select();
  setCSRF();
}

function setCSRF() {
  var token = $( 'meta[name="csrf-token"]' ).attr( 'content' );

  $.ajaxSetup( {
    beforeSend: function ( xhr ) {
      xhr.setRequestHeader( 'X-CSRF-Token', token );
    }
  });
}
