import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'ageFrom', 'ageTo' ]

  connect() {
    console.log("Curated channels", this.element)
    this.locale = null;
    this.gender = null;
  }

  updateLocale(event) {
    this.locale = event.target.dataset.value;
    this.filterRows();
  }

  updateGender(event) {
    this.gender = event.target.dataset.value;
    this.filterRows();
  }

  filterRows() {
    document.querySelectorAll('table tr.filterable-row').forEach((row) => {
      row.classList.remove('d-none');
    });

    this.filterAgeFrom();
    this.filterAgeTo();
    this.filterGender();
    this.filterLocale();
  }

  filterAgeFrom() {
    const ageFrom = parseInt(this.ageFromTarget.value) || 0;

    document.querySelectorAll('table tr.filterable-row').forEach((row) => {
      const rowAgeFrom = parseInt(row.dataset.ageFrom);
      if (rowAgeFrom < ageFrom) {
        row.classList.add('d-none')
      }
    });
  }

  filterAgeTo() {
    const ageTo = parseInt(this.ageToTarget.value);

    if (!ageTo) {
      return;
    }

    document.querySelectorAll('table tr.filterable-row').forEach((row) => {
      const rowAgeTo = parseInt(row.dataset.ageTo);
      if (rowAgeTo > ageTo) {
        row.classList.add('d-none')
      }
    });
  }

  filterGender() {
    if(!this.gender || this.gender === 'all') {
      return;
    }

    document.querySelectorAll('table tr.filterable-row').forEach((row) => {
      if (row.dataset.gender !== this.gender) {
        row.classList.add('d-none');
      }
    });
  }

  filterLocale() {
    if(!this.locale || this.locale === 'all') {
      return;
    }

    document.querySelectorAll('table tr.filterable-row').forEach((row) => {
      if (row.dataset.locale !== this.locale) {
        row.classList.add('d-none')
      }
    });
  }
}
